.fixed-image {
    width: 100%;
    height: auto;
    max-height: 200px;
    object-fit: contain;
  }
  
  .activity-card {
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .activity-card .card-body {
    padding: 20px;
  }
  
  .image-col {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .card-title {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .card-subtitle {
    font-size: 1.2rem;
    margin-top: 20px;
    font-weight: bold;
  }
  
  .card-text {
    font-size: 1rem;
    line-height: 1.5;
    color: #555;
  }
  
  