.track-item {
    display: flex;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    padding: 10px;
    background-color: #f9f9f9;
  }
  
  .track-info {
    flex: 1;
    padding: 10px;
  }
  
  .track-info h3 {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
  
  .track-info p {
    margin-bottom: 10px;
  }
  
  .track-images {
    display: flex;
    align-items: center;
  }
  
  .track-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 10px;
  }
  