.typewriter-text.shrink {
    font-size: 1em !important;
  }
  
  .typewriter-text.grow {
    font-size: 4em !important;
  }
  .typewriter-text.small {
    font-size: 1em !important;
  }

.scroll-icon {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 2em;
    animation: bounce 2s infinite;
    color: white;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateX(-50%) translateY(0);
    }
    40% {
      transform: translateX(-50%) translateY(-15px);
    }
    60% {
      transform: translateX(-50%) translateY(-10px);
    }
  }

.app:hover{
  filter: blur(5px); 
  cursor: pointer;
}