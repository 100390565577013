.recently-played-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px;
}

.recently-played {
  width: 100%;
  justify-content: space-around;
}

.recently-played-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.album-image {
  height: 200px;
  object-fit: cover;
}

.card-content {
  padding: 15px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.track-name {
  font-size: 1.2em;
  color: #333;
}

.artist-names {
  font-size: 1em;
  color: #666;
}

.played-at {
  font-size: 0.9em;
  color: #999;
}

.button {
  width: 100%;
}
