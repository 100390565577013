/* .container-fluid {
    background: linear-gradient(135deg, , #1ED760);
  } */
  
  .alert-heading {
    font-size: 2rem;
    font-weight: bold;
  }
  
  .alert p {
    font-size: 1.25rem;
  }
  
  button {
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
  }
  