.content {
    background-color: white; 
    border-radius: 15px; 
    height: 500px;
    width: 700px;
    padding: 30px;
}
.title {
    font-size: x-large;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.info-button {
    background-color: #212529;
    border-color: #212529;
}
.info-button:hover{
    background-color: #212529;
    border-color: #212529;
}