.mt-5 {
    margin-top: 3rem !important;
  }
  
  .mt-6 {
    margin-top: 3rem !important;
    margin-bottom: 5rem !important;
  }
  .mb-4 {
    margin-bottom: 1.5rem !important;
  }
.white-text{
  color: white;
}