.game-layout {
  padding: 40px;
}

.image-text-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.song-image {
  max-width: 100%;
  height: auto;
}

.song-text {
  font-size: 1.5em;
  margin-top: 20px;
}

.wordle-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.guess-row {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.box{
  border-radius: 10px;
  border: 2px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  margin: 2px;
  background-color: #fff;
}
.non-box {
  display: flex;
  align-items: flex-end;    /* Aligns the text at the bottom */
}
.space {
  width: 10px;
  height: 50px;
}

.box.green {
  background-color: green;
  color: #fff;
}

.box.yellow {
  background-color: yellow;
  color: #000;
}

.box.gray {
  background-color: gray;
  color: #fff;
}

.game-over {
  margin-top: 20px;
  font-size: 1.5em;
  color: red;
}
.hints-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.hint-row {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
}

.hint-title {
  font-weight: bold;
  font-size: 18px;
  color: white;
  margin-right: 20px;  
}

.hint-values {
  display: flex;
  gap: 10px;
}

.hint-item {
  margin: 0;
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 16px;
}

.game-title-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin-bottom: 15px;
  gap: 15px; /* Optional: add space between elements */
  height: 100%; /* Ensure the container takes the full height of its parent */
}

.game-title {
  color: white;
  font-size: 40px;
  padding: 0;
}

.game-title-p {
  color: white;
  margin: 0;
}

.game-title-logo {
  height: 50px;
  width: 183px;
  margin-left: 0;
}
body {
  background-color: #212529;
}